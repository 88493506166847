import React, {Component} from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import Slider from "react-slick"
import Scroll_top_img from "../../images/scroll-arrow.png"   

import Atg_brand from "../../images/atg/ATG-brand.png"
import Quote_icon from "../../images/atg/quote_icon.png"
import Atg_story1 from "../../images/atg/atg-story-img-01.png"
import Atg_story2 from "../../images/atg/atg-story-img-02.png"
import Story_icon1 from "../../images/atg/stoey-icon01.png"
import Story_icon2 from "../../images/atg/stoey-icon02.png"
import Story_icon3 from "../../images/atg/stoey-icon03.png"
import Story_icon4 from "../../images/atg/stoey-icon04.png"
import Atg_website from "../../images/atg/atg-website-img.png"
import Atg_app_left from "../../images/atg/atg-app-left-img.png"
import Atg_app_right from "../../images/atg/atg-app-right-img.png"
import Atg_app_image from "../../images/atg/atg-app-image.png"
import Atg_app_image2 from "../../images/atg/atg-app-image-2.png"
import Atg_app_image3 from "../../images/atg/atg-app-image-3.png"
import Atg_packing from "../../images/atg/packaging.png"
import Atg_growkit from "../../images/atg/grow-kit.png"
import Atg_seedkit from "../../images/atg/seed-kit.png"
import Atg_stars from "../../images/atg/stars.gif"
import {Helmet} from "react-helmet"
import "animate.css/animate.min.css"

import Calltoaction from "../../components/Call-to-action"

const casestudy__slider = { 
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
}

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
        <Layout>
          <Helmet>
            <title>Marketing, Design & Branding Case - All That Grows | CueForGood</title>
            <meta name="description"
                  content="AllThatGrows is a leading ecommerce store centered around the cause #GrowYourOwnFood. Read the Case Study on how CueForGood helped incorporate the brand's vision in its site design and marketing."/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0, minimum-scale=1.0" />
            <meta charset="utf-8"/>
            <link rel="canonical" href={this.state.url}/>

            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title" content="Marketing, Design & Branding Case - All That Grows | CueForGood"/>
            <meta property="og:description"
                  content="AllThatGrows is a leading ecommerce store centered around the cause #GrowYourOwnFood. Read the Case Study on how CueForGood helped incorporate the brand's vision in its site design and marketing."/>
            <meta property="og:image" content="https://www.cueforgood.com/images/allthatgrow.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="AllThatGrows is a leading ecommerce store centered around the cause #GrowYourOwnFood. Read the Case Study on how CueForGood helped incorporate the brand's vision in its site design and marketing."/>
            <meta name="twitter:title" content="Marketing, Design & Branding Case - All That Grows | CueForGood"/>
            <meta name="twitter:site" content="@CueForGood"/>
            <meta name="twitter:image" content="https://www.cueforgood.com/images/allthatgrow.jpg"/>
          </Helmet>
          <div className="cb_expertiseInner atg-brand-page amazon-page">
            <div className="case_study_page ">
              <div className="case-wrap  ">
                <div className="ww-sec-one ">
                  <div className="container">
                    <h3>Case Study </h3>

                    <h1>ALLTHATGROWS</h1>

                    <div className="ww-top-img display-block">
                      <img src={Atg_brand} alt="AllThatGrows"/>
                    </div>
                    <div className="ww-main-heading-1">
                      Brand identity, packaging and marketing campaigns for <br/>
                      AllThatGrows, a line of non-hybrid, non-GMO seed varieties
                    </div>
                  </div>
                </div>

                <div className="increase-venue ">
                  <div className="container">
                    <div className="revenue-outer">
                      <div className="venue-left">
                        <h3>82.28%</h3>
                      <p>YEAR ON YEAR GROWTH IN REVENUE </p>
                      </div>
                      <div className="venue-right">
                        <h3>32.50%</h3>
                        <p>INCREASE IN THE NUMBER OF ORDERS</p>
                      </div>
                    </div>
                    <div className="comparing_col">
                      <p>*Comparing Jan 19 - Nov 19 with the same period in 2018.</p>
                    </div>
                  </div>
                </div>
                <div className="cb_good_section call-action-component">
                  <Calltoaction sid="allthatgrows"/>
                </div>

                <div className="testimonial-block ">
                  <div className="container">
                    <div className="testimonial-row">
                      <div className="testimonial-block-left">
                        <img src={Quote_icon} alt="AllThatGrows"/>
                        <p>
                          “The breadth of experienced teams pulled in by CueForGood to
                          realize the AllThatGrows website and packaging was just
                          simply amazing. They were very helpful, friendly and
                          professional to work with. Clear and constant communication
                          with very precise deliverables helped us plan the launch
                          very efficiently.{" "}
                        </p>
                      </div>
                      <div className="testimonial-block-right">
                        <p>
                          {" "}
                          The store set up is effortlessly easy for my team to
                          understand and work on every day. The website and the
                          packaging tell our ever-evolving story beautifully - exactly
                          what we were looking for.”{" "}
                        </p>
                        <p>
                          <strong>Karan Mahajan,</strong> Co-Founder
                          <br/>
                          <i>AllThatGrows</i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="client-situation ">
                  <div className="container">
                    <div className="top-details text-center">
                      <h2 className="heading_main">The story</h2>
                      <p>
                        AllThatGrows, the eCommerce store committed to the cause of
                        #GrowYourOwnFood, made its debut in Jan 2017. From the outset,
                        AllThatgrows was centred around creating sustainable and
                        self-sufficient home gardens.
                      </p>
                    </div>

                    <div className="situation-block">
                      <div className="situation-block-left ">
                        <p>
                          The idea of growing zucchini, tomatoes, beans, gourds etc is
                          still foreign to most Indian households for lack of time in
                          some cases and a dearth of awareness in others. There are
                          very few products as overlooked as a packet of seeds.
                        </p>
                        <p>
                          With a passion for growing things, AllThatGrows team wanted
                          to promote and preserve the agricultural and culinary
                          heritage while parallelly introducing new gardening concepts
                          in India.
                        </p>
                      </div>
                      <div className="situation-block-right">
                        <img
                            className="story_img-01"
                            src={Atg_story1}
                            alt="Allthatgrows"
                        />
                        <img
                            className="story_img-02"
                            src={Atg_story2}
                            alt="Allthatgrows"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ww-sec-points  process-undertaken ">
                  <div className="container">
                    <div className="process_section">
                      <h6> 
                        The <span>CueForGood</span> team closely worked with the
                        co-founders of AllThatGrows to incorporate the vision of the
                        brand into the website design and user experience. The brand
                        identity for AllThatGrows was then conceptualized and
                        implemented to convey the product as natural, pure and
                        authentic.
                      </h6>
                      <ul>
                        <li className="process1">
                          <img src={Story_icon1} alt="Allthatgrows"/>
                          <p>
                            complete website design and development and
                            implementation.
                          </p>
                        </li>
                        <li className="process2">
                          <img src={Story_icon2} alt="Allthatgrows"/>
                          <p>responsive theme development</p>
                        </li>
                        <li className="process3">
                          <img src={Story_icon3} alt="Allthatgrows"/>
                          <p>comprehensive marketing campaigns</p>
                        </li>
                        <li className="process4">
                          <img src={Story_icon4} alt="Allthatgrows"/>
                          <p>different categories of packaging</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="challenge ">
                  <div className="container">
                    <div className="challnege-bloock">
                      <div className="top-details text-center">
                        <h2 className="heading_main">Challenges</h2>
                        <p className="sub-dec">
                          Since the brand was devout to educating the customer about
                          the much-neglected significance of preserving local heirloom
                          seed varieties and an earth-conscious lifestyle, the
                          graphic/packaging identity of the brand and the marketing,
                          both paid and organic, needed to emphasize the product as
                          natural, pure and authentic.{" "}
                        </p>
                      </div>
                      <div className="challnege-bloock-left">
                        <div className="single-block">
                          <span><strong>01</strong></span>
                          <div className="detail-challnege">
                            <p>
                              Identifying and developing a uniquely specific palette
                              of colours that represent the spirit of growing one’s
                              one food
                            </p>
                          </div>
                        </div>
                        <div className="single-block">
                          <span><strong>02</strong></span>
                          <div className="detail-challnege">
                            <p>
                              Designing and building the brand’s identity with regards
                              to the website and the packaging centred around the
                              soulful connection to nature
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="challnege-bloock-left">
                        <div className="single-block">
                          <span><strong>03</strong></span>
                          <div className="detail-challnege">
                            <p>
                              Working with the marketing team to discern a very
                              specific set of audience and customer persona that can
                              become the potential customer base for the brand
                            </p>
                          </div>
                        </div>
                        <div className="single-block">
                          <span><strong>04</strong></span>
                          <div className="detail-challnege">
                            <p>
                              Creating highly usable, searchable and customizable
                              pages that can result in clear conversions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" atg-advantage-section">
                  <div className="container">
                    <div className="ww-table-info display-block">
                      <h3>The CueForGood Advantage</h3>
                      <p className="sub-dec">
                        Our team worked closely with the brand on creating an identity
                        that contributed to integrated marketing and communication
                        strategy which positions the brand as a wholesome gardening
                        brand.
                      </p>
                      <div className="advantage-image-row">
                        <div className="advantage-left">

                          <img src={Atg_website} alt="Allthatgrows"/>

                        </div>
                        <div className="advantage-right">
                          <h4 className="heading_main">
                            Website designing & development:
                          </h4>
                          <p>
                            The homepage of the website was designed to incorporate
                            the ‘seasonality’ element which is a major factor in sales
                            or this particular product. The look and feel of the
                            website were designed for visual cohesion across different
                            pages. USPs, call to actions, sign up boxes were
                            implemented to stand out for increased user engagement.
                          </p>
                          <p>
                            Our inspiration during the design and development of the
                            website was the user’s journey, or in this case, the
                            gardener’s journey. Hence the navigation needed to appeal
                            to the gardener's looking specific information about
                            different categories of seeds.
                          </p>
                        </div>
                      </div>
                      <div className="advantage-slide-row">
                        <div className="advantage-left">
                          <h4 className="heading_main">The How to Grow App </h4>
                          <p>
                            To guide customers through the gardening process, we
                            created a “How to grow” app - which provided easy access
                            to all relevant information in one place.{" "}
                          </p>
                          <p>
                            We developed a user-centric experience that highlighted
                            the brand’s extensive gardening expertise and crafted a
                            highly visual and easy to navigate and fully responsive
                            website.
                          </p>
                        </div>
                        <div className="advantage-right">
                    <span className="ad-left-img">
                      {" "}
                      <img src={Atg_app_left} alt="Allthatgrows"/>
                    </span>
                          <div className="ww-client-slider">
                            <ul>
                              <Slider {...casestudy__slider}>
                                <li>
                                  <img src={Atg_app_image} alt="Allthatgrows"/>{" "}
                                </li>
                                <li>
                                  <img src={Atg_app_image2} alt="Allthatgrows"/>{" "}
                                </li>
                                <li>
                                  <img src={Atg_app_image3} alt="Allthatgrows"/>{" "}
                                </li>
                              </Slider>
                            </ul>
                          </div>
                          <span className="ad-right-img">
                      <img src={Atg_app_right} alt="Allthatgrows"/>{" "}
                    </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="strategy ">
                    <div className="container">
                      <div className="strategy-bloock">
                        <div className="tab-content ">
                          <div className="strategy-bloock-right">
                            <img id="packaging-tab1" className="active" src={Atg_packing} alt="Allthatgrows"/>
                            <img id="packaging-tab2" className="hide" src={Atg_growkit} alt="Allthatgrows"/>
                            <img id="packaging-tab3" className="hide" src={Atg_seedkit} alt="Allthatgrows"/>
                          </div>
                          <div className="strategy-bloock-left">
                            <h2 className="heading_main">Packaging</h2>
                            <p>
                              To launch and start shipping, the brand needed versatile
                              packaging solutions to serve the variety of product
                              categories. Further, the seeds require cool and dry
                              storage to stay usable. The earthy aesthetic and raw
                              texture of kraft paper was used in packaging to embrace
                              the au naturale look for the brand.{" "}
                            </p>
                            <ul className="packaging-tabs">
                              <li className="active">
                                <a href="#packaging-tab1" className="focus-visible">
                                  seed packet
                                </a>
                              </li>
                              <li>
                                <a href="#packaging-tab2" className="focus-visible">grow kit</a>
                              </li>
                              <li>
                                <a href="#packaging-tab3" className="focus-visible">seed kit</a>
                              </li>
                            </ul>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="atg-marketing-block display-block">
                    <div className="container">
                      <div className="mk-left">
                        <h4>Marketing Automation & Email Marketing</h4>
                        <p>
                          A brand-specific messaging on MailChimp for subscribers was
                          used to deliver the right communication at the right time.
                          With marketing automation and a host of newsletters
                          including behavioural, promotional, informative, we were
                          able to build a strong audience base and keep them engaged.
                        </p>
                      </div>
                      <div className="mk-right">
                        <h4>Campaign Management</h4>
                        <p>
                          Targeted on capturing the new and old audience, we
                          implemented a comprehensive ad campaign for the brand with
                          Adwords, Digital Advertising, Blogging, Category Text,
                          FB/Instagram Ads to spread the word. The digital
                          communication for the different campaigns was centred around
                          elementary copy highlighting the benefits of the product and
                          authentic imagery to build credibility.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="result-section display-block">
                    <div className="container">
                      <div className="top-details text-center">
                        <h2>
                          <strong>Result:</strong> A Successful Digital Store Inspired
                          by Nature
                        </h2>
                        <p>
                          The month on month growth, irrespective of the highly
                          volatile nature of the seed industry
                        </p>
                        <img src={Atg_stars} alt="Allthatgrows"/>
                      </div>
                      <div className="result-blocks">
                        <div className="result-left">
                          <p>
                            Post website development, customers could find the brand
                            easily and as a result the offline orders increased by{" "}
                            <strong>74%</strong>.
                          </p>
                          <p>
                            A <strong>32.50%</strong> increase in orders in 2019
                            (Jan-Nov), compared to the same period in 2018.{" "}
                          </p>
                          <p>
                            Email marketing has emerged as the{" "}
                            <strong>3rd best</strong> revenue channel, after paid
                            campaigns and organic searches.
                          </p>
                        </div>
                        <div className="result-right">
                          <p>
                            A constant year on year growth in revenue with a{" "}
                            <strong>82.28%</strong> increase in 2019 (Jan - Nov) when
                            compared to the same period in 2018.
                          </p>
                          <p>
                            An increase of <strong>71.42%</strong> in new users
                            through organic search, in 2019 (Jan-Nov), compared to the
                            same period in 2018.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cb_good_section call-action-component">
                  <div className="service_weoffer cb_testimonails">
                  <Calltoaction sid="allthatgrows2"/>
                  </div>
                </div>

                <div id="scroll-to" className="case-scroll">
                  <a href="#" title="Scroll To Top" className="focus-visible">
                    <img src={Scroll_top_img} alt="Scroll To Top"/>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Clutch/>
        </Layout>
    )
  }
}
